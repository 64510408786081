import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { ChangeContext, Options } from 'ng5-slider';
import { ToastrService } from 'ngx-toastr';
import { AdvanceModel, getAnalyticsModel } from 'src/app/models/Analytics/analytics.model';
import { CategoryAndMerchantModel, CategoryList, DownloadModel, ExpenseAndReportModel, FilterModal, StatusListModel } from 'src/app/models/Common/common.model';
import { ExpenseListModel } from 'src/app/models/Expenses/expenses.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ExpensesService } from 'src/app/services/expenses.service';
import { PlatformService } from 'src/app/services/platformbase.service';
import * as _ from "lodash";
import { Constants } from 'src/app/Constants';
import { CommonService } from 'src/app/services/common-services/common.service';
import { DatechangeService } from 'src/app/services/common-services/datechange.service';
import { IonRouterOutlet, IonSelect, ModalController, NavController } from '@ionic/angular';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { SharedService } from 'src/app/services/common-services/shared.service';
import { CustomDatePickerComponent } from '../custom-date-picker/custom-date-picker.component';
// import { CalendarResult } from 'ion2-calendar';
import { debounceTime, filter } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MatDatepicker } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { DateRangeComponent } from '../date-range/date-range.component';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  platformType: number;
  userInfo: any;
  analyticsData: any;
  analyticsOverViewData: any;
  public doughnutChartLabels = [];
  public doughnutChartData = [];
  public doughnutChartType: ChartType = "doughnut";
  public _backgroundColors = [{ backgroundColor: ["#ed8f8f", "#c991e3", "#82e7d4", "#ff7f0e", "#2ca02c", "1f77b4"] }];
  categoryLabels = [];
  categoryChartData = [];
  public CategoryLegend = false;
  public categoryoptions: ChartOptions = {
    responsive: true,
    legend: { display: false }
  }
  categoryAnalyList: any;
  expenseReported: any;
  dateTo: any;
  dateFrom: any;
  departmentChartData = [];
  departmentLabels = [];
  public departmentChart: ChartType = "doughnut";
  topExpenseReported: any;
  public barChartOptApproval: ChartOptions = {
    responsive: true,
    legend: { display: false }
  };
  public barApprovalLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartApprovData: ChartDataSets[] = [];
  public barChartOptexpReport: ChartOptions = {
    responsive: true,
    legend: { display: false }
  };
  public barChartempReport: ChartOptions = {
    responsive: true,
    legend: { display: false },
    maintainAspectRatio: false
  };
  public barChartempReportPlugins = [];
  public barempReportChartLegend = false;
  empreportChartData = [];
  empreportLabels = [];
  public empReportChartType: ChartType = 'bar'
  showLoading = false;
  title: string;
  tableHide = false;
  hideColumns = [];
  isPageLoad: any;
  expenseList: any[];
  isExpenseCreated: boolean;
  isApicallinProgress: boolean;
  expenseAndReportModel = new ExpenseAndReportModel();
  filterModal = new FilterModal();
  draft = true;
  minAndMax: any;
  options: any;
  pageNumber = 1;
  pageSize = 50;
  minPrice = 0;
  maxPrice = 0;
  checkAll: any;
  showExpLoading = false;
  showFilter = false;
  dropdownFlag = false;
  selectedcategory = [];
  checkAllCat: boolean;
  categoryState: boolean;
  frmDate: any;
  tDate: any;
  amount: any;
  mobMaxDate: any;
  categoryList: any;
  selectedStatus: any[];
  indeterminateState: boolean;
  statusList = [];
  checkAllStatus: any;
  statusState: boolean;
  minDate: Date;
  maxDate: Date;
  downloadTime: any;
  downloadActionPoolCount = 0;
  downloadModel = new DownloadModel();
  downloadBtn = false;
  departmentData: any;
  empReportList: any;
  maxAmount = 0;
  dummyList = [1, 2, 3];
  dummyLoader = [1, 2, 3, 4, 5];
  viewData: any;
  public barChartmilageData: ChartDataSets[] = [];
  public barchartmilageLabels: Label[] = [];
  public barChartOptmilage: ChartOptions = {
    responsive: true,
  };
  public barChartmilagePlugins = [];
  public barchartmilageLegend = false;
  public barChartmilageType: ChartType = 'bar';
  milageList: any;
  advanceModel = new AdvanceModel()
  advanceList: any;
  MilegetotalList: any;
  empWiseRptTotal: any;
  expenseCount: void;
  totalAmount: void;
  categoryTotalList: any;
  activeLogList: any;
  activeData: any;
  remainAmt: any;
  approvalDistrubData: any;
  showDateFilter: boolean;
  disToDate: any;
  disFromDate: any;
  approvalList: any;
  isUser: any;
  datePipe = new DatePipe('en-US');
  selectedrange: any = "ThisMonth";
  checkingVariable: string = 'true';
  previousDates: any;
  mydate: any;
  datePick: boolean = false;
  maxDateStr: any = moment(new Date().getTime()).add(2000, 'years').format('YYYY-MM-DD');
  minDateStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');

  maxDateToStr: any = moment(new Date().getTime()).add(2000, 'years').format('YYYY-MM-DD');
  minDateToStr: any = moment(new Date().getTime()).subtract(2000, 'years').format('YYYY-MM-DD');
  actionNameStr: any;

  @ViewChild('mySelect', { static: false }) selectRef: IonSelect;
  @ViewChild("downloadLoader", { static: false }) private downloadLoader;
  modalRef: BsModalRef;
  preDate: string;
  bandWiseReportData: any;
  costCenterWiseReportData: any;
  currencySymbol: any;

  //categoryChartOptions : any;
  categoryChartOptions = {
    animationEnabled: true,
    title: {
      text: ""
    },
    data: [{
      type: "doughnut",
      //	yValueFormatString: "#,###.##'%'",
      indexLabel: "{name}",
      dataPoints: [
        { y: 0, name: "" },
        { y: 0, name: "" },
      ]
    }]
  }

  // line overview

  public lineChartData: ChartDataSets[] = [{ data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Company Expenses' }];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'June', 'July', 'August', 'September', 'October', 'november', 'December']

  xyAxisLabel(options, value) {
    // console.log(options);
    options.scales.xAxes[0].display = value;
    options.scales.yAxes[0].display = value;
    return options;
    // this.lineChartOptions.scales.xAxes[0].display = value;
    // this.lineChartOptions.scales.yAxes[0].display = value;
    // return this.lineChartOptions;
  }

  public lineChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {

          scaleLabel: {
            display: true,
            labelString: ''

          },
          // ticks: {
          //   callback: (value) => {
          //     return `₹ ${value.toLocaleString()}`;
          //   }
          // },
          gridLines: {
            drawOnChartArea: false,
            color: '#E5E5E5',
          },
        display: false

        }],
      xAxes: [{
        // ticks: {
        //   fontStyle: 'normal',
        //   fontSize: 13,
        //   autoSkip: false,
        //   maxRotation:  window.innerWidth < 1100 ? 90 : 0,
        //   minRotation: window.innerWidth < 1100 ? 90 : 0,
        // },
        gridLines: {
          drawOnChartArea: false,
          color: '#E5E5E5',
        },
        display: false
      }]
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },

  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'white',
      borderColor: 'orange',
      pointBackgroundColor: 'orange',
      pointBorderColor: 'white',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';


  // Employee line chart

  // line overview

  public lineEmpChartData: ChartDataSets[] = [{ data: [0, 0, 0, 0, 0], label: 'Company Expenses' }];
  public lineEmpChartLabels: Label[] = ['', '', '', '', '']

  public lineEmpChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: ''

          },
          ticks: {
            callback: (value) => {
              return `₹ ${value.toLocaleString()}`;
            }
          },
          gridLines: {
            drawOnChartArea: false,
            color: '#E5E5E5',
          }
        }],
      xAxes: [{
        gridLines: {
          drawOnChartArea: false,
          color: '#E5E5E5',
        }

      }]
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },

  };
  public lineEmpChartColors: Color[] = [
    {
      backgroundColor: 'white',
      borderColor: 'darkblue',
      pointBackgroundColor: 'darkblue',
      pointBorderColor: 'white',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
  ];
  public lineEmpChartLegend = true;
  public lineEmpChartType: ChartType = 'line';

  // band barchart

  public barBandChartOptions: ChartOptions = {
    responsive: true,

    scales: {
      xAxes: [{
        ticks: {
          fontSize: 8
        },
        gridLines: {
          drawOnChartArea: false,
          color: '#f5f6f9',
        }

      }],

      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 8,
            callback: function (label, index, labels) {
              return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
          gridLines: {
            drawOnChartArea: true,
            color: '#f5f6f9',
          }
        },
      ],
    },
    tooltips: {
      enabled: true,
      intersect: true,
      titleFontSize: 12,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barBandChartLabels: Label[] = [];
  public barBandChartType: ChartType = 'bar';
  public barBandChartLegend = true;
  public barBandChartPlugins = [];
  public barBandChartData: ChartDataSets[] = [{ data: [], label: 'Bands', barPercentage: 0.8 }];
  public barBandChartColors: Color[] = [
    {
      backgroundColor: '#28ba62',
      borderColor: '#28ba62',
      pointBackgroundColor: 'darkblue',
      pointBorderColor: 'white',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
  ];

  // cost center barchart

  public barCostCenterChartOptions: ChartOptions = {
    responsive: true,

    scales: {
      xAxes: [{
        ticks: {
          fontSize: 8
        },
        gridLines: {
          drawOnChartArea: false,
          color: '#f5f6f9',
        }
      }],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: 8,
            callback: function (label, index, labels) {
              return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
          },
          gridLines: {
            drawOnChartArea: true,
            color: '#f5f6f9',
          }
        },
      ],
    },
    tooltips: {
      enabled: true,
      intersect: true,
      titleFontSize: 12,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  public barCostCenterChartLabels: Label[] = [];
  public barCostCenterChartType: ChartType = 'bar';
  public barCostCenterChartLegend = true;
  public barCostCenterChartPlugins = [];
  // public barCostCenterChartData: ChartDataSets[];
  public barCostCenterChartData: ChartDataSets[] = [{ data: [], label: 'Cost Center', barPercentage: 0.8 }];
  public barCostCenterChartColors: Color[] = [
    {
      backgroundColor: '#36abe0',
      borderColor: '#36abe0',
      pointBackgroundColor: 'darkblue',
      pointBorderColor: 'white',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
  ];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  bandChartBool: boolean = false;
  costCenterChartBool: boolean = false;
  categoryBool: boolean = false;
  empChartBool: boolean = false;
  companyChartBool: boolean = false;
  showDownloadOptions: boolean = false;
  _fromdate: any;
  _todate: any;
  maxExpire: Date;
  allowMax:any;
  @ViewChild("fromdate", { read: undefined, static: true }) fromdate: MatDatepicker<Date>;
  @ViewChild("todate", { read: undefined, static: true }) todate: MatDatepicker<Date>;
  customReport:any;

  constructor(private platformService: PlatformService, private AnalyticsService: AnalyticsService,
    private expenseService: ExpensesService, private routerOutlet: IonRouterOutlet,
    private toaster: ToastrService, private toastr: ToastrService,
    private translate: TranslateService, private datechangeService: DatechangeService,
    private commonService: CommonService, public router: NavController, public sharedService: SharedService, private modalService: BsModalService,
    public modalController: ModalController, private modelService: BsModalService) { }

  //  selectedValue = this.Durations[1].value;
  getAnalyticsModel = new getAnalyticsModel()
  filterSubject: Subject<string> = new Subject();
  reportSubject: Subject<string> = new Subject();
  ngOnInit() {
    this.filterSubject.pipe(
      debounceTime(800)
    ).subscribe((searchTerm) => {
      this.getMangerUsers(searchTerm);
    });
    this.reportSubject
      .pipe(debounceTime(1000))
      .subscribe((type) => {
        this.callReportFunction(type);
    });
    let date = new Date();
    this.minDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.maxExpire = new Date(date.getFullYear() + 40, 11);
    this.allowMax = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.platformType = this.platformService.getPlatformType();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.currencySymbol = String.fromCharCode(parseInt(this.userInfo?.currencySymbol?.replace('&#', ''), 10));
    this.dateTo = moment().format('DD-MM-YYYY');
    this.disToDate = new Date();
    this.getAnalyticsModel.toDate = this.dateTo;
    this.getAnalyticsModel.fromDate = moment().subtract(1, 'months').format('DD-MM-YYYY');
    this.filterModal.toDate = this.dateTo;
    this.filterModal.fromDate = moment().subtract(1, 'months').format('DD-MM-YYYY');
    this.disFromDate = moment().subtract(1, 'months').format();
    this.getAnalyticsModel.userId = this.userInfo.userId;
    this.getAnalyticsModel.userGUID = this.userInfo.userGuId;
    this.getAnalyticsModel.legalEntityId = this.userInfo.legalEntityId;
    this.getAnalyticsModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.getAnalyticsModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    // if ((JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2)) {
    //   this.getAnalyticsModel.viewType = this.userInfo.roleId;
    // } else {
    //   this.getAnalyticsModel.viewType = 1;
    // }
    this.getAnalyticsModel.recordType = 2;

    this.mydate = new Date();
    this.previousDates = {
      value: 'Last Month',
      selectedFromDate: '',
      selectedToDate: '',

    }
    this.getAnalytics();
    this.getAnalyticsOverviewData()
    this.title = 'Expense Overview';
    this.getCategorylist();
    this.getStatusList();
    this.getEmpWiseReportList();
    this.getCategories();
    this.getMangerUsers();
    this.getLimtsUtilisationReport();
    this.getExpenseClaimReport();
    this.getCardClaimReport();

    this.sharedService.getUserToggle().subscribe((val) => {
      this.isUser = val;
    });
    this.disToDate = moment().subtract(0, 'months').format();
  }

  //getAnalyticsOverviewData

  getAnalyticsOverviewData() {
    this.getAnalyticsModel.recordType = 1;
    // this.getAnalyticsModel.viewType = this.userInfo.roleId;
    this.getAnalyticsModel.viewType = (JSON.parse(sessionStorage.getItem('isadmin')) == true) ? this.userInfo.roleId : 1;
    this.showLoading = true;
    this.AnalyticsService.getAnalyticsOverviewData(this.getAnalyticsModel).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.analyticsOverViewData = res.payload;
        this.analyticsOverViewData = [];
        var lineDataAmountVals = [];
        var lineDataMounthVals = [];
        res.payload.overview.forEach(elm => {
          lineDataAmountVals.push(elm.amount);
          lineDataMounthVals.push(elm.monthName);
        });

        this.lineChartData = [{ data: lineDataAmountVals, label: 'Company Expenses' }];
        this.lineChartLabels = lineDataMounthVals;

        this.showLoading = false;
      } else {
        this.showLoading = false;
        this.toaster.error('API Error')
      }
    })
  }


  getAnalytics() {
    this.getAnalyticsModel.recordType = 1;
    if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
      this.getAnalyticsModel.viewType = this.userInfo.roleId;
    } else {
      this.getAnalyticsModel.viewType = 1;
    }
    // this.getAnalyticsModel.viewType = this.userInfo.roleId;
    // this.getAnalyticsModel.viewType = (JSON.parse(sessionStorage.getItem('isadmin')) == true) && (this.userInfo.roleId == 2) ? 2 : 1
    this.showLoading = true;
    this.AnalyticsService.getAnalyticsData(this.getAnalyticsModel).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.analyticsData = res.payload;
        if (this.analyticsData.categoryWiseReport != null) {
          this.categoryAnalyList = this.analyticsData.categoryWiseReport.categories;
          this.categoryTotalList = this.analyticsData.categoryWiseReport
          this.showCategory();
        }

        if (this.analyticsData.departmentWiseReport) {
          this.departmentData = this.analyticsData.departmentWiseReport.departmenetReportList

        }
        if (this.analyticsData.employeeWiseReport) {
          this.empReportList = this.analyticsData.employeeWiseReport.employeeReportList;
        }
        if (this.analyticsData.topExpensesReported !== null) {
          this.topExpenseReported = this.analyticsData.topExpensesReported.employeeReportedList
          this.empReport();
        }
        if (this.analyticsData.approvalDistributionVsTime) {
          this.approvalDistrubData = this.analyticsData?.approvalDistributionVsTime?.approvalDistributionList
        }
        if (this.analyticsData.bandWiseReport) {
          this.bandWiseReportData = this.analyticsData.bandWiseReport.bandReportList;
        }
        if (this.analyticsData.costCenterWiseReport) {
          this.costCenterWiseReportData = this.analyticsData.costCenterWiseReport.costCenterReportList;
        }

        this.department();
        this.getAdvanceList();
        this.approveGraph();
        //this.getMilegeList();
        this.getActiveLogList();
        this.getApprovalList();
        this.bandWise();
        this.costCenterWise();
        this.showLoading = false;
      } else {
        this.showLoading = false;
        this.toaster.error('API Error')
      }
    })
  }
  bandWise() {
    //  this.bandWiseReportData = [];
    if (this.bandWiseReportData?.length > 0) {
      this.bandChartBool = true;
      let amtArray = [];
      this.bandWiseReportData.forEach(element => {
        amtArray.push(element.amount);
        this.barBandChartLabels.push(element.name)
      });
      var barbandval = 0.04;
      if (this.bandWiseReportData.length == 1) {
        barbandval = 0.02;
      } else if (this.bandWiseReportData.length < 3) {
        barbandval = 0.04;
      }
      else if (this.bandWiseReportData.length < 5) {
        barbandval = 0.06;
      } else if (this.bandWiseReportData.length < 7) {
        barbandval = 0.08;
      } else {
        barbandval = 0.1;
      }
      this.barBandChartData = [
        {
          data: amtArray, label: 'Band',
          barPercentage: barbandval
        },

      ];
    } else {
      this.bandChartBool = false;
      this.barBandChartLabels = [];
      this.barBandChartData = [];
      this.barBandChartData = [{ data: [], label: '' }];
      this.barBandChartColors = [
        {
          backgroundColor: 'white',
          borderColor: 'white',
        },
      ];
    }
  }
  costCenterWise() {
    if (this.costCenterWiseReportData?.length > 0) {
      this.costCenterChartBool = true;
      let amtArray = [];
      this.costCenterWiseReportData.forEach(element => {
        amtArray.push(element.amount);
        this.barCostCenterChartLabels.push(element.name)
      });
      var barval = 0.04;
      if (this.costCenterWiseReportData.length == 1) {
        barval = 0.04;
      } else if (this.costCenterWiseReportData.length < 5) {
        barval = 0.1;
      } else if (this.costCenterWiseReportData.length < 7) {
        barval = 0.2;
      } else {
        barval = 0.4;
      }
      this.barCostCenterChartData = [
        {
          data: amtArray, label: 'Cost Center',
          barPercentage: barval
        },
      ];
    } else {
      this.costCenterChartBool = false;
      this.barCostCenterChartLabels = [];
      this.barCostCenterChartData = [];
      this.barCostCenterChartData = [{ data: [], label: '' }];
      this.barCostCenterChartColors = [
        {
          backgroundColor: 'white',
          borderColor: 'white',
        },
      ];
    }

  }

  viewExpense(status?, category?, id?, depmId?) {
    let filters = { statuslist: [], searchText: '', forUserIds: [], departmentIds: [], fromDate: this.getAnalyticsModel.fromDate, toDate: this.getAnalyticsModel.toDate };
    if (status !== '') {
      if(status == 4){
        filters.statuslist.push(4,5);
      }else if(status == 2){
       // filters.statuslist.push(status);
       filters.statuslist.push(2,3,6,7);
      }else{
        filters.statuslist.push(status);
      }
    } else {
      filters.statuslist = [];
    }
    if (depmId) {
      filters.departmentIds.push(depmId)
    }
    if (id) {
      filters.forUserIds.push(id)
    }
    // filters.fromDate = this.previousDates.selectedFromDate;
    // filters.toDate = this.previousDates.selectedToDate

    filters.searchText = category;
    this.AnalyticsService.setExpStatus(filters);

    let navInfo = {
      url: 'analytics'
    };
    sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    if (this.actionNameStr == "CATEGORYWISE" || this.actionNameStr == "MILEAGEWISE" ||
      this.actionNameStr == "EMPLOYEEWISE" || this.actionNameStr == "ACTIVELOGS" ||
      this.actionNameStr == "DISTVSTIME" || this.actionNameStr == "DEPARTMENTWISE") {
      //MILEAGEWISE , EMPLOYEEWISE , ACTIVELOGS , DISTVSTIME DEPARTMENTWISE
    } else {
      this.router.navigateRoot(["expenses"])
    }

  }

  backToAnalytics() {
    this.tableHide = false;
    this.title = 'Expense Overview';
    this.getAnalytics();
  }

  showhideColumns(val: string) {
    let column = true;
    if (this.hideColumns.indexOf(val) > -1) {
      column = false;
    }
    return column;
  }


  selectAllexpenses(event?) {
    setTimeout(() => {
      this.expenseList.forEach((item) => {
        item.isSelected = event ? false : this.checkAll;
      });
    });
  }

  public customlabels = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let value: any = data.datasets[0].data[tooltipItem.index];
          let currencyPipe = new CurrencyPipe('en');
          let formattedNumber = currencyPipe.transform(value, 'INR', 'symbol');
          value = formattedNumber //value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return value;

        }
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: function (value, index, values) {
            let currencyPipe = new CurrencyPipe('en');
            let formattedNumber = currencyPipe.transform(value, 'INR', 'symbol');
            value = formattedNumber;//value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            return value;
          }
        },
        scaleLabel: {
          display: true,
        }
      }]
    }

  }
  changeOptions(minValue, maxValue) {
    const newOptions: Options = Object.assign({}, this.options);
    newOptions.floor = minValue;
    newOptions.ceil = maxValue;
    this.minPrice = minValue;
    this.maxPrice = maxValue;
    this.options = newOptions;
  }

  toggleColumns(e, val: string) {
    if (e.target.checked === true) {
      this.hideColumns.push(val);
    } else {
      const index = this.hideColumns.indexOf(val);
      if (index > -1) {
        this.hideColumns.splice(index, 1);
      }
    }
  }


  empReport() {

    this.empreportChartData = [];
    var empNameVals = [];
    var empAmtVals = [];

    if (this.empReportList.length > 0) {
      this.empChartBool = true;
      this.empReportList.forEach(elm => {
        empNameVals.push(elm.employeeName);
        empAmtVals.push(elm.amount);
      });

      this.lineEmpChartData = [{ data: empAmtVals, label: 'Employees' }];
      this.lineEmpChartLabels = empNameVals;
    } else {
      this.empChartBool = false;
      this.lineEmpChartLabels = [];
      this.lineEmpChartData = [];
      this.lineEmpChartData = [{ data: [], label: '' }];
      this.lineEmpChartColors = [
        {
          backgroundColor: 'white',
          borderColor: 'white',
        },
      ];
    }


  }

  showCategory() {
    this.categoryChartData = [];

    if (this.categoryAnalyList) {
      this.categoryAnalyList.forEach(elm => {
        let obj = {
          y: elm.amount,
          name: elm.categoryName
          //name: elm.categoryName + '-' +  elm.currencyCode + elm.amount
        }
        this.categoryChartData.push(obj);
      });
      this.categoryChartOptions = {
        animationEnabled: true,
        title: {
          text: ""
        },
        data: [{
          type: "doughnut",
          //	yValueFormatString: "#,###.##'%'",
          indexLabel: "{name}",
          dataPoints: this.categoryChartData
        }]
      }

    }
  }

  approveGraph() {
    let approved = [];
    let rejected = [];
    let pending = [];
    this.approvalDistrubData?.forEach(element => {
      approved.push(element.approvedPercentage)
      rejected.push(element.rejectedPercentage)
      pending.push(element.pendingPercentage)
      this.barApprovalLabels.push(element.effectiveDays)
    });
    this.barChartApprovData = [
      { data: approved, label: 'Approved', stack: 'a' },
      { data: rejected, label: 'Rejected', stack: 'a' },
      { data: pending, label: 'Pending', stack: 'a' }
    ];

    this.barChartOptApproval = {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            max: 100,
            callback: function (value, index, values) {
              value = value + '%'
              return value;
            }
          },
          scaleLabel: {
            display: true,
          }
        }]
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let value = tooltipItem.value + '%';
            return value;
          }
        }
      }
    };

    this.barChartType = 'bar';
  }


  getFilterDates(ev) {
    this.showDateFilter = false;
    if (ev) {
      this.disToDate = ev.to;
      this.getAnalyticsModel.toDate = moment(new Date(ev.to)).format('DD-MM-YYYY');
      this.getAnalyticsModel.fromDate = moment(new Date(ev.from)).format('DD-MM-YYYY');
      this.filterModal.fromDate = moment(new Date(ev.from)).format('DD-MM-YYYY');
      this.filterModal.toDate = moment(new Date(ev.to)).format('DD-MM-YYYY');
      this.disFromDate = ev.from;
      this.getAnalytics();
      this.getLimtsUtilisationReport();
      this.getExpenseClaimReport();
      this.getCardClaimReport();
    }
  }
  department() {
    this.departmentChartData = [];
    this.departmentLabels = [];
    if (this.departmentData) {
      this.departmentData.forEach(element => {
        this.departmentChartData.push(element.departmentAmount);
        this.departmentLabels.push(element.departmentName)
      });
    }
    this.departmentChart = "doughnut";
  }

  navToReports() {
    if (this.platformType == 1) {
      let navInfo = {
        url: 'analytics'
      };
      sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    }
    this.router.navigateRoot('expenses');
  }

  showFiltersection() {
    this.showFilter = !this.showFilter;
    this.dropdownFlag = false;
  }

  showCategoryList() {
    this.tableHide = true
    this.title = 'Category wise distribution'
    this.actionNameStr = 'CATEGORYWISE';
    this.viewData = this.categoryAnalyList;
    this.expenseCount = this.categoryTotalList.totalExpenseCount;
    this.totalAmount = this.categoryTotalList.totalExpenseAmount

  }

  CategoryMerchantModel = new CategoryAndMerchantModel();

  getCategorylist() {
    this.CategoryMerchantModel.companyId = this.userInfo.companyId;
    this.CategoryMerchantModel.companyGUID = this.userInfo.companyGuId;
    this.CategoryMerchantModel.legalEntityId = this.userInfo.legalEntityId;
    this.CategoryMerchantModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    this.CategoryMerchantModel.status = 1;
    this.CategoryMerchantModel.categoryType = null;
    this.commonService
      .getCategorylist(this.CategoryMerchantModel)
      .subscribe((res) => {
        if (!res.isError && res.errorCode === 0) {
          const listarray = res.payload.categoryList;
          this.categoryList = listarray.map((list) => new CategoryList(list));
        } else if (res.isError === true) {
          this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
        }
      });
  }

  selectSingleCategory(list) {
    if (list.isSelected) {
      this.selectedcategory.push(list.categoryId);
    } else {
      const index = _.indexOf(this.selectedcategory, list.categoryId);
      this.selectedcategory.splice(index, 1);
    }
    this.filterModal.categoryIds = this.selectedcategory;
    if (
      this.categoryList.length !== this.selectedcategory.length &&
      this.selectedcategory.length > 0
    ) {
      this.checkAllCat = false;
      this.categoryState = true;
    } else if (this.selectedcategory.length === 0) {
      this.checkAllCat = false;
      this.categoryState = false;
    } else {
      this.checkAllCat = true;
      this.categoryState = false;
    }

  }

  resetPaging() {
    this.pageNumber = 1;
    this.expenseList = [];
    this.expenseList.length = 0;
  }

  resetFilters(list) {
    list.forEach((item) => {
      item.isSelected = false;
    });
  }

  applyfilter() {
    this.showFilter = true;
    this.resetPaging();
    if (this.frmDate !== undefined && this.tDate === undefined) {
      this.tDate = this.mobMaxDate;
      this.filterModal.toDate = moment(new Date()).format(Constants.mobileDateFormat);
    }
    if (this.frmDate === undefined && this.tDate !== "") {
      this.frmDate = this.tDate;
      this.filterModal.fromDate = this.tDate;
    }
    // this.createFilters();
    this.filterModal.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
    this.filterModal.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;

    this.getAnalyticsModel.fromDate = this.frmDate ? moment(new Date(this.frmDate)).format(Constants.mobileDateFormat) : undefined;
    this.getAnalyticsModel.toDate = this.tDate ? moment(new Date(this.tDate)).format(Constants.mobileDateFormat) : undefined;

    //this.returnExpSegment();
    // this.maxDate = new Date();

    //  this.mobMaxDate = moment(new Date()).format(Constants.mobileCalenderFormat);
  }

  getStatusList() {
    const type = { type: +1 };
    this.commonService.statusList(type).subscribe((res) => {
      if (!res.isError && res.errorCode === 0) {
        const listarray = res.payload;
        this.statusList = listarray.map((list) => new StatusListModel(list));
      } else if (res.isError === true) {
        this.toastr.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    });
  }

  selectSingleStatus(list) {
    if (list.isSelected) {
      this.selectedStatus.push(list.statusId);
      this.filterModal.statusIds = this.selectedStatus;
    } else {
      const index = _.indexOf(this.selectedStatus, list.statusId);
      this.selectedStatus.splice(index, 1);
      this.filterModal.statusIds = this.selectedStatus;
    }
    this.filterModal.statusIds = this.selectedStatus;
    if (
      this.statusList.length !== this.selectedStatus.length &&
      this.selectedStatus.length > 0
    ) {
      this.checkAllStatus = false;
      this.statusState = true;
    } else if (this.selectedStatus.length === 0) {
      this.checkAllStatus = false;
      this.statusState = false;
    } else {
      this.checkAllStatus = true;
      this.statusState = false;
    }
  }

  getfromDate(e) {
    this.frmDate = e.target.value;
    const date = this.datechangeService.convertCalendarToAPI(e.target.value);
    this.filterModal.fromDate = date;
    this.minDate = new Date(this.frmDate);
  }

  gettodate(e) {
    this.tDate = e.target.value;
    const date = this.datechangeService.convertCalendarToAPI(e.target.value);
    this.filterModal.toDate = date;
    this.maxDate = new Date(this.tDate);

  }
  downloadCancel() {
    this.modalRef.hide();
  }
  downloadActionType: any;
  bulkExcel(type, stausIds?, recordType?) {
    const config = {
      ignoreBackdropClick: true,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.downloadLoader, config);
    this.downloadActionType = type;
    this.filterModal.statusIds = stausIds || [];
    this.downloadModel.recordType = recordType || null;
    this.bulkDownload();
  }

  bulkDownload(list?) {
    this.downloadBtn = true;
    this.downloadModel.referenceIds = null;
    this.filterModal.isDraft = null;
    this.downloadModel.filterBy = this.filterModal;
    this.downloadModel.userId = this.userInfo.userId;
    this.downloadModel.userGUID = this.userInfo.userGuId;
    this.downloadModel.entityType = 1;
    this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    this.downloadModel.legalEntityId = this.userInfo.legalEntityId;
    this.downloadModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    // this.downloadModel.viewType = this.userInfo.roleId;
    if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
      this.downloadModel.viewType = this.userInfo.roleId;
    } else {
      this.downloadModel.viewType = 1;
    }
    this.downloadModel.isApprover = false;
    this.downloadModel.downloadActionType = this.downloadActionType;
    this.commonService.downloadList(this.downloadModel).subscribe(res => {
      if (!res.error && res.payload) {
        this.downloadPoolingStart(res.payload, 3000)
      }
    })
  }
  downloadPoolingStart(request, time) {
    this.downloadActionPoolCount++;
    this.downloadModel.jobId = request.jobId;
    this.downloadModel.jobGUID = request.jobGUID;
    this.commonService.downloadpoolStart(this.downloadModel).subscribe(res => {
      if (!res.isError && !res.payload?.resultFileURL && this.downloadActionPoolCount < 30) {

        this.downloadTime = setTimeout(() => {
          this.downloadPoolingStart(res.payload, time);
        }, time);
      } else if (!res.isError && res.payload.status == 3) {
        this.downloadBtn = false;
        this.showDownloadOptions = false;
        window.open(res['payload']['resultFileURL'], '_self');
        this.toaster.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
        this.downloadActionPoolCount = 0;
        this.modalRef.hide()
      } else {
        this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
        this.downloadBtn = false;
        this.showDownloadOptions = false;
        this.downloadActionPoolCount = 0;
        this.modalRef.hide()
      }
    })
  }

  getAdvanceList() {
    this.getAnalyticsModel.fromDate = moment(this.disFromDate).format("DD-MM-YYYY");
    this.getAnalyticsModel.toDate = moment(this.disToDate).format("DD-MM-YYYY");
    this.getAnalyticsModel.recordType = 2;
    this.AnalyticsService.getAdvanceData(this.getAnalyticsModel).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.advanceList = res.payload;
      }
    })
  }

  showAdvance() {
    if (this.platformType == 1) {
      let navInfo = {
        url: 'analytics'
      };
      sessionStorage.setItem('toPathInfo', JSON.stringify(navInfo));
    }
    this.router.navigateRoot('advance');
    // this.tableHide = true
    // this.title = 'Advance'
    // this.viewData = this.advanceList.advanceDataList;
    // this.expenseCount = this.advanceList.totalAdvanceCount;
    // this.totalAmount = this.advanceList.totalAdvanceAmount;
    // this.remainAmt = this.advanceList.totalRemainingAmount
  }

  // getMilegeList() {
  //   this.getAnalyticsModel.recordType = 1;
  //   this.getAnalyticsModel.fromDate = moment(this.disFromDate).format("DD-MM-YYYY");
  //   this.getAnalyticsModel.toDate = moment(this.disToDate).format("DD-MM-YYYY")
  //   this.AnalyticsService.getMilegeData(this.getAnalyticsModel).subscribe(res => {
  //     if (!res.isError && res.errorCode === 0) {
  //       this.MilegetotalList = res.payload;
  //     }
  //   })
  // }

  showMilageList() {
    this.tableHide = true;
    this.title = 'Milage Wise Details';
    this.actionNameStr = 'MILEAGEWISE';
    this.viewData = this.MilegetotalList.mileageDataList;
    this.expenseCount = this.MilegetotalList.totalCount;
    this.totalAmount = this.MilegetotalList.totalAmount
  }

  getEmpWiseReportList() {
    this.getAnalyticsModel.recordType = 1;
    this.getAnalyticsModel.fromDate = moment(this.disFromDate).format("DD-MM-YYYY");
    this.getAnalyticsModel.toDate = moment(this.disToDate).format("DD-MM-YYYY")
    this.AnalyticsService.getEmpwiseRprtData(this.getAnalyticsModel).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.empWiseRptTotal = res.payload;
      }
    })
  }

  showEmpWiseReports() {
    this.tableHide = true
    this.title = 'Employee Wise Details'
    this.actionNameStr = 'EMPLOYEEWISE';
    this.viewData = this.empWiseRptTotal.employeeDataList;
    this.expenseCount = this.empWiseRptTotal.totalCount;
    this.totalAmount = this.empWiseRptTotal.totalAmount;
  }

  getActiveLogList() {
    this.getAnalyticsModel.recordType = 1;

    this.getAnalyticsModel.fromDate = moment(this.disFromDate).format("DD-MM-YYYY"); //newcode
    this.getAnalyticsModel.toDate = moment(this.disToDate).format("DD-MM-YYYY");

    this.AnalyticsService.getActiveLogsData(this.getAnalyticsModel).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.activeLogList = res.payload;
      }
    })
  }
  showActiveLogs() {
    this.tableHide = true
    this.title = 'Active Logs'
    this.actionNameStr = 'ACTIVELOGS';
    this.viewData = this.activeLogList.userActiveLogList;
  }

  showEmployeeViolations() {}
  showTripStatus() {}


  openDateTimeFilter() {
    this.showDateFilter = !this.showDateFilter;
  }

  getApprovalList() {
    this.getAnalyticsModel.recordType = 1;
    this.getAnalyticsModel.fromDate = moment(this.disFromDate).format("DD-MM-YYYY");
    this.getAnalyticsModel.toDate = moment(this.disToDate).format("DD-MM-YYYY")
    this.getAnalyticsModel.action = 'approved'
    this.AnalyticsService.getApprovalData(this.getAnalyticsModel).subscribe(res => {
      if (!res.isError && res.errorCode === 0) {
        this.approvalList = res.payload;
      }
    })
  }

  showApprovalDetails() {
    this.tableHide = true;
    this.title = 'Time Taken For Approval';
    this.actionNameStr = 'DISTVSTIME';
    this.viewData = this.approvalList.approvalDistributionList;
  }

  showDepartment() {
    this.tableHide = true;
    this.title = 'Department Wise Reports';
    this.actionNameStr = 'DEPARTMENTWISE';
    this.viewData = this.analyticsData.departmentWiseReport.departmenetReportList;
    this.expenseCount = this.analyticsData.departmentWiseReport.totalDepartmentCount;
    this.totalAmount = this.analyticsData.departmentWiseReport.totalDepartmentAmount
  }

  returnDasbord() {
    this.router.navigateRoot(["dashboard"]);
  }

  /*mobile*/
  selectedDate: string;
  async openDateSheet(From: string, format?: string) {
    const modal = await this.modalController.create({
      component: CustomDatePickerComponent,
      componentProps: {
        selectedDate: this.selectedDate ? this.selectedDate : new Date(),
        fromDate: new Date('1981-01-01'),
        toDate: new Date(),
        checkingVariable: true,
      },
      cssClass: 'custom-bottom-sheet'
    });
    modal.onDidDismiss().then(result => {
      if (result.role === 'changed' && result.data) {
        this.selectedDate = result.data.displayDate;

        if (From == 'from') {
          this.disFromDate = this.datePipe.transform(this.selectedDate, 'dd MMM yyyy');
          this.getAnalyticsModel.fromDate = result.data.formattedDate;
          this.getAnalyticsModel.toDate = moment(new Date(this.disToDate)).format('DD-MM-YYYY');

        } else if (From == 'to') {
          this.disToDate = this.datePipe.transform(this.selectedDate, 'dd MMM yyyy');
          this.getAnalyticsModel.toDate = result.data.formattedDate;
          this.getAnalyticsModel.fromDate = moment(new Date(this.disFromDate)).format('DD-MM-YYYY');
        } else {

          this.disFromDate = this.datePipe.transform(result.data.fromDate, 'dd MMM yyyy');
          this.disToDate = this.datePipe.transform(result.data.toDate, 'dd MMM yyyy');

        }

        this.getAnalytics();

      }
    });
    return await modal.present();
  }

  getFromDateCall(ev) {
    // this.disFromDate = this.datePipe.transform(ev.detail.value, 'dd MMM yyyy');
    //this.disToDate = this.datePipe.transform(ev.detail.value, 'dd MMM yyyy');
    let today = new Date();
    let date = today;
    this.maxDateToStr = moment(new Date(date)).format('YYYY-MM-DD');
    this.minDateToStr = moment(new Date(ev.detail.value)).format('YYYY-MM-DD');

  }

  getToDateCall(ev) {

    this.tDate = moment(ev.detail.value).format("MM-DD-YYYY");
    this.filterModal.toDate = moment(ev.detail.value).format("DD-MM-YYYY");
    this.mobMaxDate = moment(new Date(this.tDate)).format(Constants.mobileCalenderFormat);
    //this.disToDate = moment(ev.detail.value).format("DD-MM-YYYY");
  }


  onSelectChange(selectedValue: any) {
    this.selectedrange = selectedValue.detail.value;

    let today = new Date();
    if (selectedValue.detail.value == "Today") {

      let date = today;
      this.disFromDate = this.datePipe.transform(date, 'dd MMM yyyy');
      this.getAnalyticsModel.fromDate = moment(new Date(date)).format('DD-MM-YYYY');
      this.getAnalyticsModel.toDate = moment(new Date(this.disToDate)).format('DD-MM-YYYY');

      this.maxDateToStr = moment(new Date(this.disToDate)).format('YYYY-MM-DD');
      this.minDateToStr = moment(new Date(date)).format('YYYY-MM-DD');

      this.disToDate = this.datePipe.transform(today, 'dd MMM yyyy');

    } else if (selectedValue.detail.value == "Yesterday") {

      let date = new Date(today.setDate(today.getDate() - 1));
      this.disFromDate = this.datePipe.transform(date, 'dd MMM yyyy');
      this.getAnalyticsModel.fromDate = moment(new Date(date)).format('DD-MM-YYYY');
      this.getAnalyticsModel.toDate = moment(new Date(this.disToDate)).format('DD-MM-YYYY');

      this.maxDateToStr = moment(new Date(this.disToDate)).format('YYYY-MM-DD');
      this.minDateToStr = moment(new Date(date)).format('YYYY-MM-DD');

      this.disToDate = this.datePipe.transform(new Date(), 'dd MMM yyyy');

    } else if (selectedValue.detail.value == "Week") {

      let date = new Date(today.setDate(today.getDate() - 7));
      this.disFromDate = this.datePipe.transform(date, 'dd MMM yyyy');
      this.getAnalyticsModel.fromDate = moment(new Date(date)).format('DD-MM-YYYY');
      this.getAnalyticsModel.toDate = moment(new Date(this.disToDate)).format('DD-MM-YYYY');

      this.maxDateToStr = moment(new Date(this.disToDate)).format('YYYY-MM-DD');
      this.minDateToStr = moment(new Date(date)).format('YYYY-MM-DD');

      this.disToDate = this.datePipe.transform(new Date(), 'dd MMM yyyy');

    } else if (selectedValue.detail.value == "ThisMonth") {
      this.preDate = moment().startOf('month').format();
      let date = new Date(this.preDate);
      // let date = new Date(today.setDate(today.getDate() - 30));
      this.disFromDate = this.datePipe.transform(date, 'dd MMM yyyy');
      this.getAnalyticsModel.fromDate = moment(new Date(date)).format('DD-MM-YYYY');
      this.getAnalyticsModel.toDate = moment(new Date(this.disToDate)).format('DD-MM-YYYY');

      this.maxDateToStr = moment(new Date(this.disToDate)).format('YYYY-MM-DD');
      this.minDateToStr = moment(new Date(date)).format('YYYY-MM-DD');

      this.disToDate = this.datePipe.transform(new Date(), 'dd MMM yyyy');

    } else if (selectedValue.detail.value == "LastMonth") {

      this.preDate = moment().subtract(1, 'months').startOf('month').format();
      let date = new Date(this.preDate);
      // let date = new Date(today.setDate(today.getDate() - 60));
      this.disFromDate = this.datePipe.transform(date, 'dd MMM yyyy');
      this.getAnalyticsModel.fromDate = moment(new Date(date)).format('DD-MM-YYYY');
      this.getAnalyticsModel.toDate = moment(new Date(this.disToDate)).format('DD-MM-YYYY');

      this.maxDateToStr = moment(new Date(this.disToDate)).format('YYYY-MM-DD');
      this.minDateToStr = moment(new Date(date)).format('YYYY-MM-DD');

      this.disToDate = this.datePipe.transform(new Date(), 'dd MMM yyyy');

    }
    this.getAnalytics();
  }

  openSelect() {
    this.selectRef.open()
  }

  featchValues(event) {
    this.previousDates.value = event.value;
    this.previousDates.selectedFromDate = event.selectedFromDate;
    this.previousDates.selectedToDate = event.selectedToDate;
  }

  downloadData(actionName) {
    this.actionNameStr = actionName;
    this.analyticsDownload();
  }

  analyticsDownload() {
    const config = {
      ignoreBackdropClick: true,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.downloadLoader, config);
    this.downloadBtn = true;
    // this.getAnalyticsModel.toDate = this.dateTo;
    // this.getAnalyticsModel.fromDate = moment().subtract(1, 'months').format('DD-MM-YYYY');
    this.getAnalyticsModel.actionName = this.actionNameStr;
    this.commonService.downloadAnalyticsAll(this.getAnalyticsModel).subscribe(res => {
      if (!res.error && res.payload) {
        this.downloadPoolingStart(res.payload, 3000);
      }
    });
  }
  showBandDetails() {
    this.tableHide = true;
    this.title = 'Band Wise Details';
    this.actionNameStr = 'BANDWISE';
    this.viewData = this.analyticsData.bandWiseReport.bandReportList;
    this.expenseCount = this.analyticsData.bandWiseReport.totalBandCount;
    this.totalAmount = this.analyticsData.bandWiseReport.totalBandAmount
  }
  showCostDetails() {
    this.tableHide = true;
    this.title = 'Cost Center Wise Details';
    this.actionNameStr = 'COSTCENTERWISE';
    this.viewData = this.analyticsData.costCenterWiseReport.costCenterReportList;
    this.expenseCount = this.analyticsData.costCenterWiseReport.totalCostCenterCount;
    this.totalAmount = this.analyticsData.costCenterWiseReport.totalCostCenterFormattedAmount
  }
  activeUserSummary(downloadactiveUserSummary?: TemplateRef<any>) {
    const config = {
      ignoreBackdropClick: true,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modelService.show(downloadactiveUserSummary, config);
  }
  onCloseModel() {
    this.modalRef.hide();
  }
  fromdateChange(event) {
    this.minDate = new Date(event.target.value);
    this._fromdate = moment(event.value).format("DD-MM-YYYY");
  }
  todateChange(event) {
    this._todate = moment(event.value).format("DD-MM-YYYY");
  }

  onSaveClick() {
    this.showDownloadOptions = true;
    this.downloadActiveUserSummary();
  }
  downloadActiveUserSummary(value?) {
    this.showDownloadOptions = true;
    let requestModel = {
      toDate: this._todate,
      fromDate: this._fromdate,
      userId: this.userInfo.userId,
      userGUID: this.userInfo.userGuId,
      legalEntityId: this.userInfo.legalEntityId,
      legalEntityGUID: this.userInfo.legalEntityGuId,
      recordType: 1
    }
    this.commonService.downloadActiveUserSummary(requestModel).subscribe(res => {
      if (!res.error && res.payload) {
        this.downloadPoolingStart(res.payload, 3000);
      }else{
        this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    });
  }



  CallAPI(){
    // this.commonService.getCustomReport(requestModel).subscribe(res => {
    //   if (!res.error && res.payload) {
    //     this.downloadPoolingStart(res.payload, 3000);
    //   }else{
    //     this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
    //   }
    // });

    const config = {
      ignoreBackdropClick: true,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.downloadLoader, config);
    // this.downloadActionType = type;
    // this.filterModal.statusIds = stausIds || [];
    // this.downloadModel.recordType = recordType || null;

    this.downloadBtn = true;
    this.downloadModel.referenceIds = null;
    this.filterModal.isDraft = null;
    this.downloadModel.userPromt =this.customReport
    this.downloadModel.dynamicReportType = "ALL_EXPENSES"
    this.downloadModel.filterBy = this.filterModal;
    this.downloadModel.userId = this.userInfo.userId;
    this.downloadModel.userGUID = this.userInfo.userGuId;
    this.downloadModel.entityType = 1;
    this.downloadModel.isAdminView = JSON.parse(sessionStorage.getItem('isadmin'));
    this.downloadModel.legalEntityId = this.userInfo.legalEntityId;
    this.downloadModel.legalEntityGUID = this.userInfo.legalEntityGuId;
    // this.downloadModel.viewType = this.userInfo.roleId;
    if (JSON.parse(sessionStorage.getItem('isadmin')) == true) {
      this.downloadModel.viewType = this.userInfo.roleId;
    } else {
      this.downloadModel.viewType = 1;
    }
    this.downloadModel.isApprover = false;
    this.downloadModel.downloadActionType = this.downloadActionType;
    this.commonService.getCustomReport(this.downloadModel).subscribe(res => {
      this.modalRef.hide()
      if (res.errorCode == 0) {
          this.downloadBtn = false;
          this.showDownloadOptions = false;
          window.open(res['payload'], '_self');
          this.toaster.success(this.translate.instant('alerts.downloadsucces'), this.translate.instant('alerts.succes'));
          this.modalRef.hide()
      } else {
          this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
    }, err => {
      this.toastr.error('No data available for the selected filters.', 'Error');
      this.modalRef.hide()
    });

  }

  status = [
    { id: '', name: 'All' },
    { id: 1, name: 'Draft' },
    { id: 2, name: 'Awaiting Approval' },
    { id: 3, name: 'Awaiting Finance Approval' },
    { id: 4, name: 'Rejected' },
    { id: 5, name: 'Finance Rejected' },
    { id: 6, name: 'Finance Approved' },
    { id: 7, name: 'Paid' }
  ];

  limitsUtilisationData: any = [];
  expenseClaimData: any = [];
  cardClaimData: any = [];
  loadingLimits: boolean = false;
  loadingExp: boolean = false;
  loadingCard: boolean = false;
  luCharts: any = {
    table: null,
    pieChart: null,
    barChart: null,
    barList: null,
    barChartIndex: 0,
    userIds: [''],
    userNames: ['All'],
    categoryIds: [''],
    categoryNames: ['All'],
    statusIds: [''],
    statusNames: ['All']
  };
  ecCharts = {
    table: null,
    pieChart: null,
    barChart: null,
    barList: null,
    barChartIndex: 0,
    userIds: [''],
    userNames: ['All'],
    categoryIds: [''],
    categoryNames: ['All'],
    statusIds: [''],
    statusNames: ['All']
  };
  ccCharts = {
    table: null,
    pieChart: null,
    barChart: null,
    barList: null,
    barChartIndex: 0,
    userIds: [''],
    userNames: ['All'],
    categoryIds: [''],
    categoryNames: ['All'],
    statusIds: [''],
    statusNames: ['All']
  };


  loadLUChart() {
    // Chart Data
    let tableData = [];
    let pieData = [];
    this.luCharts.barList = [];
    this.limitsUtilisationData?.forEach(user => {
      let barData = [];
      user.categoryLimits.forEach((category, i) => {
        category.cityWiseLimitInfo.forEach((cityWise, j) => {
          tableData.push(
            this.userInfo?.isEnableClaimTypes ? [(i == 0 && j == 0) ? user.userName : '', (i == 0 && j == 0) ? user.claimType : '', j == 0 ? category.categoryName : '', j == 0 ? category.countries : '', cityWise.cityName, this.currencyFormat(cityWise.currencyIcon, cityWise.f_limit), this.currencyFormat(cityWise.currencyIcon, cityWise.f_utilised), this.currencyFormat(cityWise.currencyIcon, cityWise.f_balance)] : [(i == 0 && j == 0) ? user.userName : '', j == 0 ? category.categoryName : '', j == 0 ? category.countries : '', cityWise.cityName, this.currencyFormat(cityWise.currencyIcon, cityWise.f_limit), this.currencyFormat(cityWise.currencyIcon, cityWise.f_utilised), this.currencyFormat(cityWise.currencyIcon, cityWise.f_balance)]);
          pieData.push([`${category.categoryName}`, cityWise.utilised]);
          barData.push([`${category.categoryName}${cityWise.cityName ? '/' + cityWise.cityName : '' }`, {v: cityWise.limit, f: `${this.currencySymbol} ${cityWise.f_limit}`}, {v: cityWise.utilised, f: `${this.currencySymbol} ${cityWise.f_utilised}`}]);
        })
      });
      this.luCharts.barList.push({
        userName: user.userName,
        data: barData
      });
    });

    // Bar Chart Data
    let f_PieData = [];
    pieData.forEach(([key, value]) => {
      if (f_PieData[key]) {
        f_PieData[key] += value;
      } else {
        f_PieData[key] = value;
      }
    });
    f_PieData = Object.entries(f_PieData);
    f_PieData = f_PieData.map(row => {
      return [row[0], row[1], `${row[0]}\n${this.currencySymbol}${row[1].toLocaleString()}`];
    });

    // Pie Chart intialization
    this.luCharts.pieChart = {
      type: 'PieChart',
      data: f_PieData,
      columnNames: ['Category', 'Utilised', {'type': 'string', 'role': 'tooltip'}],
      webOptions: {
        pieHole: 0.4,
        is3D: true,
        chartArea: { width: '90%', height: '80%' },
        colors: ['#4285F4', '#EA4335', '#FBBC05', '#34A853'],
        pieSliceText: 'none',
        legend: { position: 'bottom' }
      }
    };

    // Table Chart intialization
    this.luCharts.table = {
      type: 'Table',
      data: tableData,
      columnNames: this.userInfo?.isEnableClaimTypes ? ['Employee Name', 'Claim Type', 'Category', 'Country', 'City', '<div style="text-align: right;">Limit</div>', '<div style="text-align: right;">Utilised</div>', '<div style="text-align: right;">Balance</div>'] : ['Employee Name', 'Category', 'Country', 'City', '<div style="text-align: right;">Limit</div>', '<div style="text-align: right;">Utilised</div>', '<div style="text-align: right;">Balance</div>'],
      options: {
        width: '100%',
        height: 'auto',
        allowHtml: true,
        cssClassNames: {
          headerRow: 'table-secondary',
          tableRow: 'table-sm',
          hoverTableRow: 'table-hover'
        }
      }
    };

    this.loadLimitBarChart(this.luCharts?.barList[this.luCharts.barChartIndex], 'lu');
  }

  loadECChart() {
    // Chart Data
    let tableData = [];
    let pieData = [];
    this.ecCharts.barList = [];
    this.expenseClaimData?.forEach(user => {
      let barData = [];
      user.claims?.forEach((claim, i) => {
        claim.categories?.forEach((category, j) => {
          tableData.push([i == 0 ? user.userName : '', j == 0 ? claim.claimCode : '', category.categoryName, this.currencyFormat(category.currencyIcon, category.f_claimed), this.currencyFormat(category.currencyIcon, category.f_paid), this.currencyFormat(category.currencyIcon, category.f_balance), category.status]);
          pieData.push([category.categoryName, category.paid]);
          barData.push([claim.claimCode, {v: category.claimed, f: `${this.currencySymbol} ${category.f_claimed}`}, {v: category.paid, f: `${this.currencySymbol} ${category.f_paid}`}]);
        });
      });
      this.ecCharts.barList.push({
        userName: user.userName,
        data: barData
      });
    });

    // Bar Chart Data
    let f_PieData = [];
    pieData.forEach(([key, value]) => {
      if (f_PieData[key]) {
        f_PieData[key] += value;
      } else {
        f_PieData[key] = value;
      }
    });
    f_PieData = Object.entries(f_PieData);
    f_PieData = f_PieData.map(row => {
      return [row[0], row[1], `${row[0]}\n${this.currencySymbol}${row[1].toLocaleString()}`];
    });

    // Pie Chart intialization
    this.ecCharts.pieChart = {
      type: 'PieChart',
      data: f_PieData,
      columnNames: ['Category', 'Approved', {'type': 'string', 'role': 'tooltip'}],
      webOptions: {
        pieHole: 0.4,
        is3D: true,
        chartArea: { width: '90%', height: '80%' },
        colors: ['#4285F4', '#EA4335', '#FBBC05', '#34A853'],
        pieSliceText: 'value',
        legend: { position: 'bottom' }
      }
    };

    // Table Chart intialization
    this.ecCharts.table = {
      type: 'Table',
      data: tableData,
      columnNames: ['Employee Name', 'Claim ID', 'Category', '<div style="text-align: right;">Claimed</div>', '<div style="text-align: right;">Approved</div>', '<div style="text-align: right;">Balance</div>', 'Status'],
      options: {
        width: '100%',
        height: 'auto',
        allowHtml: true,
        cssClassNames: {
          headerRow: 'table-secondary',
          tableRow: 'table-sm',
          hoverTableRow: 'table-hover'
        }
      }
    };

    this.loadLimitBarChart(this.ecCharts?.barList[this.ecCharts.barChartIndex], 'ec');
  }

  loadCCChart() {
    // Chart Data
    let tableData = [];
    let pieData = [];
    this.ccCharts.barList = [];
    this.cardClaimData?.forEach(user => {
      let barData = [];
      user.claims?.forEach((claim, i) => {
        claim.categories?.forEach((category, j) => {
          tableData.push([i == 0 ? user.userName : '', j == 0 ? claim.claimCode : '', category.categoryName, this.currencyFormat(category.currencyIcon, category.f_claimed), this.currencyFormat(category.currencyIcon, category.f_paid), this.currencyFormat(category.currencyIcon, category.f_balance), category.status]);
          pieData.push([category.categoryName, category.paid]);
          barData.push([claim.claimCode, {v: category.claimed, f: `${this.currencySymbol} ${category.f_claimed}`}, {v: category.paid, f: `${this.currencySymbol} ${category.f_paid}`}]);
        });
      });
      this.ccCharts.barList.push({
        userName: user.userName,
        data: barData
      });
    });

    // Bar Chart Data
    let f_PieData = [];
    pieData.forEach(([key, value]) => {
      if (f_PieData[key]) {
        f_PieData[key] += value;
      } else {
        f_PieData[key] = value;
      }
    });
    f_PieData = Object.entries(f_PieData);
    f_PieData = f_PieData.map(row => {
      return [row[0], row[1], `${row[0]}\n${this.currencySymbol}${row[1].toLocaleString()}`];
    });

    // Pie Chart intialization
    this.ccCharts.pieChart = {
      type: 'PieChart',
      data: f_PieData,
      columnNames: ['Claim ID', 'Approved', {'type': 'string', 'role': 'tooltip'}],
      webOptions: {
        pieHole: 0.4,
        is3D: true,
        chartArea: { width: '90%', height: '80%' },
        colors: ['#4285F4', '#EA4335', '#FBBC05', '#34A853'],
        pieSliceText: 'value',
        legend: { position: 'bottom' }
      }
    };

    // Table Chart intialization
    this.ccCharts.table = {
      type: 'Table',
      data: tableData,
      columnNames: ['Employee Name', 'Claim ID', 'Category', '<div style="text-align: right;">Claimed</div>', '<div style="text-align: right;">Approved</div>', '<div style="text-align: right;">Balance</div>', 'Status'],
      options: {
        width: '100%',
        height: 'auto',
        allowHtml: true,
        cssClassNames: {
          headerRow: 'table-secondary',
          tableRow: 'table-sm',
          hoverTableRow: 'table-hover'
        }
      }
    };

    this.loadLimitBarChart(this.ccCharts?.barList[this.ccCharts.barChartIndex], 'cc');
  }

  currencyFormat(icon, value) {
    return `<div style="text-align: right;"><i class="fa ${icon}"></i> ${value}</div>`
  }

  loadLimitBarChart(chart, type?) {
    const barChartConfig = {
      type: 'BarChart',
      data: chart?.data,
      columnNames: ['Category', type == 'lu' ? 'Limit' : 'Claimed', type == 'lu' ? 'Utilised' : 'Paid'],
      options: {
        width: 600,
        height: 400,
        legend: { position: 'top', maxLines: 3 },
        bars: 'horizontal',
        bar: { groupWidth: '75%' },
        isStacked: false,
        hAxis: {
          title: 'Amount',
          format: `${this.currencySymbol}#,##,##0`,
          minValue: 0,
          titleTextStyle: {
            bold: true
          }
        },
        vAxis: {
          title: chart?.userName,
          titleTextStyle: {
            bold: true
          }
        },
      },
    };
  
    switch (type) {
      case 'lu':
        this.luCharts.barChart = barChartConfig;
        break;
      case 'ec':
        this.ecCharts.barChart = barChartConfig;
        break;
      case 'cc':
        this.ccCharts.barChart = barChartConfig;
        break;
      default:
        console.log('Chart type not found');
    }
  }

  nextLimitBarChart(type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    if (chart && chart.barChartIndex < chart.barList.length - 1) {
      chart.barChartIndex++;
      this.loadLimitBarChart(chart.barList[chart.barChartIndex], type);
    }
  }

  previousLimitBarChart(type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    if (chart && chart.barChartIndex > 0) {
      chart.barChartIndex--;
      this.loadLimitBarChart(chart.barList[chart.barChartIndex], type);
    }
  }

  removeEmployees(ev, type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    const index = chart.userIds.indexOf(ev?.value?.userId);
    if (chart) {
      chart.userIds.splice(index, 1);
      chart.userNames.splice(index, 1);
    }
    this.reportSubject.next(type);
  }

  removeCategories(ev, type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    const index = chart.categoryIds.indexOf(ev?.value?.userId);
    if (chart) {
      chart.categoryIds.splice(index, 1);
      chart.categoryNames.splice(index, 1);
    }
    this.reportSubject.next(type);
  }

  removeClaimStatus(ev, type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    const index = chart.statusIds.indexOf(ev?.value?.userId);
    if (chart) {
      chart.statusIds.splice(index, 1);
      chart.statusNames.splice(index, 1);
    }
    this.reportSubject.next(type);
  }

  addEmployees(ev, type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    if (chart) {
      if(ev.userId == '') {
        chart.userIds = [];
        chart.userNames = ['All'];
      } else {
        const emptyStringIndex = chart.userIds.indexOf("");
        if (emptyStringIndex > -1) {
          chart.userIds = [];
          chart.userNames = [];
        }
        chart.userIds.push(ev.userId);
        chart.userNames.push(ev.fullName);
      }
    }
    this.reportSubject.next(type);
  }

  addCategories(ev, type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    if (chart) {
      if(ev.categoryId == '') {
        chart.categoryIds = [''];
        chart.categoryNames = ['All'];
      } else {
        const emptyStringIndex = chart.categoryIds.indexOf("");
        if (emptyStringIndex > -1) {
          chart.categoryIds = [];
          chart.categoryNames = [];
        }
        chart.categoryIds.push(ev.categoryId);
        chart.categoryNames.push(ev.categoryName);
      }
    }
    this.reportSubject.next(type);
  }

  addClaimStatus(ev, type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    if (chart) {
      if(ev.id == '') {
        chart.statusIds = [''];
        chart.statusNames = ['All'];
      } else {
        const emptyStringIndex = chart.statusIds.indexOf("");
        if (emptyStringIndex > -1) {
          chart.statusIds = [];
          chart.statusNames = [];
        }
        chart.statusIds.push(ev.id);
        chart.statusNames.push(ev.name);
      }
    }
    this.reportSubject.next(type);
  }

  onEmployeeSearch(event: string) {
    this.filterSubject.next(event);
  }

  callReportFunction(type: string) {
    if (type === 'lu') {
      this.getLimtsUtilisationReport();
    } else if (type === 'ec') {
      this.getExpenseClaimReport();
    } else if (type === 'cc') {
      this.getCardClaimReport();
    }
  }
  
  selectedCategories(ev, type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    if (chart) {
      chart.categoryIds = ev?.map(e => e?.categoryId);
      chart.categoryNames = ev?.map(e => `${e?.categoryName}`);
    }
    this.reportSubject.next(type);

  }

  selectedClaimStatus(ev, type) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    if (chart) {
      chart.statusIds = ev?.map(e => e?.id);
      chart.statusNames = ev?.map(e => `${e?.name}`);
    }
    this.reportSubject.next(type);
  }

  CategoryModel = new CategoryAndMerchantModel();
  allCategories: any = [];
  getCategories() {
    this.CategoryModel.recordType = 1;
    this.CategoryModel.legalEntityId = this.userInfo?.legalEntityId;
    this.CategoryModel.legalEntityGUID = this.userInfo?.legalEntityGuId;
    this.commonService.getCatgryMasterlist(this.CategoryModel).subscribe((res) => {
      if (!res.isError && res.errorCode == 0) {
        this.allCategories = res.payload?.expenseCategoryList || [];
        this.allCategories.unshift({categoryId: '', categoryName: 'All'});
      }
    });
  }

  usersList: any = [];
  getMangerUsers(searchText?) {
    let reqeust = {
      "companyId": this.userInfo?.companyId,
      "companyGuid": this.userInfo?.companyGuId,
      "searchText": searchText?.term || "",
      "pageNumber": 1,
      "pageSize": 100,
      "sortColumn": "",
      "sortType": "asc",
      "userId": this.userInfo?.userId,
      "userGuid": this.userInfo?.userGuId
    }
    this.commonService.getUserManagerList(reqeust).subscribe(res => {
      if (res?.errorCode == 0 && res?.payload?.usersList?.length != 0) {
        this.usersList = res?.payload?.usersList || [];
        this.usersList.forEach(user => {
          user.fullName = `(${user?.employeeId}) ${user?.firstName} ${user?.lastName}`;
        });
        if(!searchText?.term || searchText?.term == '' ) {
          this.usersList.unshift({userId: '', fullName: 'All'});
        }
      }
    });
  }

  getLimtsUtilisationReport() {
    this.loadingLimits = true;
    const isAdmin = JSON.parse(sessionStorage.getItem('isadmin'));
    let requestModel = {
      "reportName": "LIMIT_UTILISATION",
      "legalEntityId": this.userInfo?.legalEntityId,
      "legalEntityGUID": this.userInfo?.legalEntityGuId,
      "viewType": isAdmin ? this.userInfo?.roleId : 1,
      "fromDate": this.filterModal?.fromDate,
      "toDate": this.filterModal?.toDate,
      "userIds": this.luCharts.userIds || [],
      "categoryIds": this.luCharts.categoryIds || [],
      "statusIds": this.luCharts.statusIds || [],
    }
    this.AnalyticsService.getLimtsUtilisationReport(requestModel).subscribe(res => {
      if (!res.isError && res.payload) {
        this.limitsUtilisationData = res?.payload?.response || [];
        this.loadLUChart();
      }else{
        this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
      this.loadingLimits = false;
    }, err => {
      this.loadingLimits = false;
    });
  }

  getExpenseClaimReport() {
    this.loadingExp = true;
    const isAdmin = JSON.parse(sessionStorage.getItem('isadmin'));
    let requestModel = {
      "reportName": "EXPENSE_CLAIM_SUMMARY",
      "legalEntityId": this.userInfo?.legalEntityId,
      "legalEntityGUID": this.userInfo?.legalEntityGuId,
      "viewType": isAdmin ? this.userInfo?.roleId : 1,
      "fromDate": this.filterModal?.fromDate,
      "toDate": this.filterModal?.toDate,
      "userIds": this.ecCharts.userIds || [],
      "categoryIds": this.ecCharts.categoryIds || [],
      "statusIds": this.ecCharts.statusIds || [],
    }
    this.AnalyticsService.getExpenseClaimReport(requestModel).subscribe(res => {
      if (!res.isError && res.payload) {
        this.expenseClaimData = res?.payload?.response || [];
        this.loadECChart();
      }else{
        this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
      this.loadingExp = false;
    }, err => {
      this.loadingExp = false;
    });
  }

  getCardClaimReport() {
    this.loadingCard = true;
    const isAdmin = JSON.parse(sessionStorage.getItem('isadmin'));
    let requestModel = {
      "reportName": "CARD_CLAIM_SUMMARY",
      "legalEntityId": this.userInfo?.legalEntityId,
      "legalEntityGUID": this.userInfo?.legalEntityGuId,
      "viewType": isAdmin ? this.userInfo?.roleId : 1,
      "fromDate": this.filterModal?.fromDate,
      "toDate": this.filterModal?.toDate,
      "userIds": this.ccCharts.userIds || [],
      "categoryIds": this.ccCharts.categoryIds || [],
      "statusIds": this.ccCharts.statusIds || [],
    }
    this.AnalyticsService.getCardClaimReport(requestModel).subscribe(res => {
      if (!res.isError && res.payload) {
        this.cardClaimData = res?.payload?.response || [];
        this.loadCCChart();
      }else{
        this.toaster.error(res.errorMessage, this.translate.instant('alerts.error'));
      }
      this.loadingCard = false;
    }, err => {
      this.loadingCard = false;
    });
  }

  reportDownload(type, chartType) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[chartType];
    this.showDownloadLoader();
    const isAdmin = JSON.parse(sessionStorage.getItem('isadmin'));
    let requestModel = {
      "reportName": type,
      "legalEntityId": this.userInfo?.legalEntityId,
      "legalEntityGUID": this.userInfo?.legalEntityGuId,
      "viewType": isAdmin ? this.userInfo?.roleId : 1,
      "fromDate": this.filterModal?.fromDate,
      "toDate": this.filterModal?.toDate,
      "userIds": chart.userIds || [],
      "categoryIds": chart.categoryIds || [],
      "statusIds": chart.statusIds || [],
    }

    this.downloadBtn = true;
    this.AnalyticsService.downloadReports(requestModel).subscribe(res => {
      if (!res.isError && res.payload) {
        this.downloadPoolingStart(res.payload, 3000)
      } else if(res.isError && res.errorMessage) {
        this.toastr.error(res.errorMessage, 'Error');
        this.modalRef?.hide();
      }
    })
  }

  showDownloadLoader() {
    const config = {
      ignoreBackdropClick: true,
      class: "modal-md modal-dialog-centered",
      keyboard: false,
    };
    this.modalRef = this.modalService.show(this.downloadLoader, config);
  }

  clearSelection(type, field) {
    const chartTypes = {
      lu: this.luCharts,
      ec: this.ecCharts,
      cc: this.ccCharts,
    };
  
    const chart = chartTypes[type];
    if (chart && field == 'user') {
      chart.userIds = [];
      chart.userNames = [];
    } else if (chart && field == 'category') {
      chart.categoryIds = [];
      chart.categoryNames = [];
    } else if (chart && field == 'status') {
      chart.statusIds = [];
      chart.statusNames = [];
    }
    this.reportSubject.next(type);
  }

  async dateRange(method, ...args) {
    const modal = await this.modalController.create({
      component: DateRangeComponent,
      cssClass: "date-range",
      presentingElement: this.routerOutlet.nativeEl,
      backdropDismiss: false
    });
    modal.onDidDismiss().then(res => {
      if (res?.data) {
        this.filterModal.fromDate = res?.data?.fDate;
        this.filterModal.toDate = res?.data?.tDate;
        if(method == 'reportDownload') {
          this.reportDownload(args[0], args[1]);
        } else if(method == 'downloadData') {
          this.downloadData(args[0]);
        } else if(method == 'bulkExcel') {
          this.bulkExcel(args[0], args[1], args[2]);
        }
      }
      this.downloadBtn = false;
    })
    return await modal.present();
  }


}